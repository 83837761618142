import axios, { AxiosResponse } from 'axios';

const PREFIX = '/api/v1';

axios.defaults.baseURL = process.env.REACT_APP_BASE_URL
  ? process.env.REACT_APP_BASE_URL + PREFIX
  : PREFIX;

export const get = async <R>(url: string): Promise<AxiosResponse<R, any>> => {
  // eslint-disable-next-line no-useless-catch
  try {
    const response = await axios.get<R>(url);
    return response;
  } catch (error) {
    throw error;
  }
};

export const post = async <R>(
  url: string,
  body: any,
  controller?: AbortController
): Promise<AxiosResponse<R, any>> => {
  // eslint-disable-next-line no-useless-catch
  try {
    const response = await axios.post<R>(url, body, {
      signal: controller?.signal,
    });
    return response;
  } catch (error) {
    throw error;
  }
};
