import { ReactNode } from 'react';
import { ImageContainer } from 'pages/Home';
import KTBIcon from 'assets/KTB.png';
import { ReactComponent as SCBIcon } from 'assets/SCB.svg';

type BankDetail = {
  thai_name: string;
  eng_name: string;
  label: string;
  icon: ReactNode;
  disabled?: boolean;
};

type BankList = {
  [key: string]: BankDetail;
};

export const bankList: BankList = {
  ktb: {
    thai_name: 'ธนาคารกรุงไทย',
    eng_name: 'KTB',
    label: 'กรุงไทย',
    icon: <ImageContainer src={KTBIcon} alt="KTB Logo" />,
  },
  // scb: {
  //   thai_name: 'ธนาคารไทยพาณิชย์',
  //   eng_name: 'SCB',
  //   label: 'ไทยพาณิชย์',
  //   icon: <SCBIcon />,
  // },
} as const;
