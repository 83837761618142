import React from 'react';
import styled from 'styled-components';
import { SpinnerCircularFixed } from 'spinners-react';

const LoadingContainer = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(16, 16, 16, 0.5);
`;

const LoadingSpinner = styled(SpinnerCircularFixed)`
  margin: auto;
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
`;

type Props = {
  open: boolean;
};

const LoadingOverlay = ({ open }: Props) => {
  if (open) {
    return (
      <LoadingContainer>
        <LoadingSpinner
          size={'5rem'}
          thickness={100}
          speed={100}
          color="rgba(37, 141, 158, 1)"
          secondaryColor="rgba(0, 0, 0, 0.44)"
        />
      </LoadingContainer>
    );
  }
  return null;
};

export default LoadingOverlay;
