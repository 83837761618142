import { useState, ChangeEvent, ReactElement, useEffect } from 'react';
import { useSearchParams, useNavigate } from 'react-router-dom';
import styled from 'styled-components';
import { useDeviceSelectors } from 'react-device-detect';

import * as apiDeepLink from 'services/deeplink';
import { getCardStatus } from 'services/lenz';

import LoadingOverlay from 'components/LoadingOverlay';

import { isValidAmount, isValidCardNumber } from 'utils';

import { ReactComponent as Logo } from 'assets/Logo.svg';
import KTBIcon from 'assets/KTB.png';
// import GooglePlayIcon from 'assets/GooglePlay_Icon.png';
// import AppStoreIcon from 'assets/AppStore_Icon.png';
// import CreditCard from 'assets/CreditCard.png';
import LogoTSB48 from 'assets/TSBOrigi.png';
import { ReactComponent as SCBIcon } from 'assets/SCB.svg';
import { ReactComponent as QRPayment } from 'assets/QRPayment.svg';
import { ReactComponent as FrowardIcon } from 'assets/ForwardIcon.svg';

const Container = styled.div`
  padding: 1.5rem;
  display: flex;
  flex-direction: column;
  gap: 1rem;
`;

const BottomContainer = styled.div`
  padding: 1.5rem;
  display: flex;
  flex-direction: column;
  gap: 0.5rem;
`;

const LogoContainer = styled.div`
  display: flex;
  justify-content: center;
  margin-bottom: 16px;
`;

const InputContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 8px;
`;

const Label = styled.div`
  font-weight: 600;
`;

const Divider = styled.div`
  height: 0px;
  border: 1px solid #eaeaea;
`;

const DividerSection = styled.div`
  height: 12px;
  background-color: #f8f8f8;
`;

const TextField = styled.input`
  height: 24px;
  font-size: 18px;
  border-radius: 8px;
  border: 1px solid #d9d9d9;
  padding: 16px;
`;

const FlexContainer = styled.div`
  display: flex;
  flex-direction: column;
  margin-bottom: 16px;
`;

type ItemContainerProps = {
  disabled?: boolean;
};

const ItemContainer = styled.div<ItemContainerProps>`
  display: flex;
  justify-content: space-between;
  width: 100%;
  align-items: center;
  border-bottom: 1px solid #eaeaea;
  padding: 16px 0;
  filter: ${(props) => (props.disabled ? 'grayscale(1)' : '')};
`;

const Item = styled.div`
  display: flex;
  gap: 16px;
  align-items: center;
`;

const TextContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
`;

const SubLabel = styled.div`
  font-size: 12px;
  color: #4fb889;
`;

const SubLabelDownloadApp = styled.div`
  font-size: 12px;
  color: #4fb889;
  cursor: pointer;
  text-decoration: underline;
`;

const Error = styled.div`
  font-size: 12px;
  color: red;
`;

export const ImageContainer = styled.img`
  width: 48px;
  height: 48px;
`;

export const ImageContainerCard = styled.img`
  width: 48px;
  height: 48px;
  border-style: solid;
  border-width: 1px;
  border-color: black;
  border-radius: 50%;
`;

export const ImageTSBGoDownload = styled.img`
  background-color:black;
  cursor:pointer;
  height:50px;
  width:150px;
`;

const textAlert = {
  color:"red",
};

type Menu = {
  label: string;
  subLabel?: string;
  icon: ReactElement;
  onClick: () => void;
  disabled: boolean;
};

const Home = () => {
  const [selectors, data] = useDeviceSelectors(window.navigator.userAgent);
  let [searchParams] = useSearchParams();
  const cardNo = searchParams.get('card_no');
  let navigate = useNavigate();
  const [cardNumber, setCardNumber] = useState<string | null>(cardNo);
  const [amount, setAmount] = useState('');
  const [typeDevice, setTypeDevice] = useState("");
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(false);

  

  const handleGetCardStatus = async () => {
    const userAgent = navigator.userAgent;
    var deviceType = '';

    if (/android/i.test(userAgent)) {
        deviceType = 'Android';
    } else if (/iPad|iPhone|iPod/.test(userAgent)) {
        deviceType = 'iOS';
    } else {
        deviceType = 'Other';
    }

    setTypeDevice(deviceType);

    try {
      if (isValidCardNumber(cardNo)) {
        const { data } = await getCardStatus({
          cardNumber: cardNo!,
        });
        if (data.code !== 0) {
          console.error(data.message);
        }
      } else {
        setCardNumber(null);
      }
    } catch (error) {
      setCardNumber(null);
    }
  };

  
  useEffect(() => {
    handleGetCardStatus();
  }, [typeDevice]);

  const handleChangeAmount = (e: ChangeEvent<HTMLInputElement>) => {
    const value = e.target.value;
    if ( Number(e.target.value)){
        if (parseFloat(value) > 1000){
          setAmount("");
          setError(true);
        }
        else{
          setAmount(value);
          setError(false);
        }
    }
    else{
      
           setAmount(value.substring(0,value.length -1));
           setError(true);
    }
    
  };

  const handleNavigateToBankQrPayment = () => {
    if (cardNumber) {
      if (isValidAmount(amount)) {
        navigate({
          pathname: '/topup/qr',
          search: `?card_no=${cardNumber}&amount=${amount ?? ''}`,
        });
      } else {
        setError(true);
      }
    }
  };

  const handleDeepLinkScb = async () => {
    try {
      if (cardNumber != null) {
        if (isValidAmount(amount)) {
          setLoading(true);
          const { data } = await apiDeepLink.getDeepLinkScb({
            cardNumber: cardNumber,
            amount: amount,
          });
          window.location.replace(data.deeplinkUrl);
        } else {
          setError(true);
        }
      }
    } catch (error) {
      console.error(error);
      alert('เกิดข้อผิดพลาดบ่างอย่าง กรุณาลองใหม่อีกครั้ง');
    } finally {
      setLoading(false);
    }
  };

  const handleDeepLinkKtb = async () => {
    const PLAY_STORE_LINK =
      'https://play.google.com/store/apps/details?id=ktbcs.netbank';
    const APP_STORE_LINK =
      'https://apps.apple.com/th/app/krungthai-next/id436753378';
    const TIME_OUT = 5000;
    try {
      if (cardNumber != null) {
        if (isValidAmount(amount)) {
          setLoading(true);
          const { data } = await apiDeepLink.getDeepLinkKtb({
            cardNumber: cardNumber,
            amount: amount,
          });
          if (selectors.isAndroid) {
              setTimeout(function () {
                window.location.replace(PLAY_STORE_LINK);
              }, TIME_OUT);
              window.location.replace(data.deeplinkUrl);
          } else if (selectors.isIOS) {
            setTimeout(function () {
              window.location.replace(APP_STORE_LINK);
            }, TIME_OUT);
            window.location.replace(data.deeplinkUrl);
          } else {
            window.location.replace(APP_STORE_LINK);
          }
        } else {
          setError(true);
        }
      }
    } catch (error) {
      console.error(error);
      alert('เกิดข้อผิดพลาดบ่างอย่าง กรุณาลองใหม่อีกครั้ง');
    } finally {
      setTimeout(function () {
        setLoading(false);
      }, TIME_OUT);
    }
  };

  const handleDeepLinkTSBGo = async () => {
    const PLAY_STORE_LINK =
      'https://play.google.com/store/apps/details?id=com.kasidej.tsbgo_plus';
    const APP_STORE_LINK =
      'https://apps.apple.com/th/app/tsb-go-plus/id6463791254?l=th';
    const TIME_OUT = 5000;
    try {
      if (cardNumber != null) {
        if (isValidAmount(amount)) {
          setLoading(true);
          const { data } = await apiDeepLink.getDeepLinkKtb({
            cardNumber: cardNumber,
            amount: amount,
          });
          if (selectors.isAndroid) {
              setTimeout(function () {
                window.location.replace(PLAY_STORE_LINK);
              }, TIME_OUT);
              window.location.replace(data.deeplinkUrl);
          } else if (selectors.isIOS) {
            setTimeout(function () {
              window.location.replace(APP_STORE_LINK);
            }, TIME_OUT);
            window.location.replace(data.deeplinkUrl);
          } else {
            window.location.replace(APP_STORE_LINK);
          }
        } else {
          setError(true);
        }
      }
    } catch (error) {
      console.error(error);
      alert('เกิดข้อผิดพลาดบ่างอย่าง กรุณาลองใหม่อีกครั้ง');
    } finally {
      setTimeout(function () {
        setLoading(false);
      }, TIME_OUT);
    }
  };

  const menuBankList: Menu[] = [
    {
      label: 'Krungthai NEXT',
      subLabel: 'ฟรีค่าธรรมเนียม',
      icon: <ImageContainer src={KTBIcon} alt="KTB NEXT Logo" />,
      onClick: handleDeepLinkKtb,
      disabled: cardNumber == null,
    },
    // {
    //   label: 'SCB Easy',
    //   subLabel: 'ค่าธรรมเนียมต่อครั้ง 2.50 บาท',
    //   icon: <SCBIcon />,
    //   onClick: handleDeepLinkScb,
    //   disabled: true,
    // },
  ];

  const checkTypeDevice = () => {
    // console.log("typeDevice:",typeDevice);
    if(typeDevice === "Android"){
      window.location.href="https://play.google.com/store/apps/details?id=com.kasidej.tsbgo_plus";
    }
    else{
      window.location.href="https://apps.apple.com/th/app/tsb-go-plus/id6463791254?l=th";
    }
  }

  // const menuTSBGOList: Menu[] =[
  //   {
  //     label: 'IOS',
  //     subLabel: '',
  //     icon: <ImageTSBGoDownload src={AppStoreIcon} alt="TSB Go Plus" />,
  //     onClick: function(){
  //       window.location.href="https://apps.apple.com/th/app/tsb-go-plus/id6463791254?l=th";
  //     },
  //     disabled: cardNumber == null,
  //   },
  //   {
  //     label: 'Android',
  //     subLabel: '',
  //     icon: <ImageTSBGoDownload src={GooglePlayIcon} alt="TSB Go Plus" />,
  //     onClick: function(){
  //       window.location.href="https://play.google.com/store/apps/details?id=com.kasidej.tsbgo_plus";
  //     },
  //     disabled: cardNumber == null,
  //   },
  // ]

  const menuOtherList: Menu[] = [
    {
      label: 'QR Code',
      subLabel: 'ฟรีค่าธรรมเนียม',
      icon: <QRPayment />,
      onClick: handleNavigateToBankQrPayment,
      disabled: cardNumber == null,
    },
  ];

  const menuCardLost: Menu[] = [
    {
      label: 'บัตรหายเงินอยู่ (TSB Go Plus)',
      subLabel: 'ดาวน์โหลดเพื่อลงทะเบียน',
      icon: <ImageContainerCard src={LogoTSB48} alt="Downdload App" />,
      onClick: checkTypeDevice,
      disabled: cardNumber == null,
    },
  ];

  const menuAppDownLoad: Menu[] = [
    {
      label: 'Download',
      subLabel: '',
      icon: <ImageContainer src={KTBIcon} alt="KTB NEXT Logo" />,
      onClick: handleDeepLinkKtb,
      disabled: cardNumber == null,
    }
  ];

  return (
    <>
      <Container>
        <LogoContainer>
          <Logo />
        </LogoContainer>
        <InputContainer>
          <Label>หมายเลขบัตร:</Label>
          <div>{cardNumber ?? 'ไม่พบหมายเลขบัตร'}</div>
        </InputContainer>
        <Divider />
        <InputContainer >
          <Label>จำนวนเงิน (บาท) </Label>
          <Label style={textAlert}> *สามารถเติมเงินได้ครั้งละไม่เกิน 1,000 บาท</Label>
          <TextField
            placeholder="0.00"
            onChange={handleChangeAmount}
            value={amount}
          />
          {error && <Error>กรุณากรอกจำนวนเงินให้ถูกต้อง</Error>}
        </InputContainer>
      </Container>
      <DividerSection />
      <BottomContainer>
        <Label>ชำระเงินผ่านแอปธนาคาร</Label>
        <FlexContainer>
          {menuBankList.map((menu: Menu, index: number) => (
            <ItemContainer
              onClick={menu.disabled ? undefined : menu.onClick}
              key={index}
              disabled={menu.disabled}
            >
              <Item>
                {menu.icon}
                <TextContainer>
                  <div>{menu.label}</div>
                  <SubLabel>{menu.subLabel}</SubLabel>
                </TextContainer>
              </Item>
              <FrowardIcon />
            </ItemContainer>
          ))}
        </FlexContainer>

        <Label>ชำระด้วยการโอนเงิน</Label>
        <FlexContainer>
          {menuOtherList.map((menu: Menu, index: number) => (
            <ItemContainer
              onClick={menu.onClick}
              key={index}
              disabled={menu.disabled}
            >
              <Item>
                {menu.icon}
                <TextContainer>
                  <div>{menu.label}</div>
                  <SubLabel>{menu.subLabel}</SubLabel>
                </TextContainer>
              </Item>
              {cardNumber != null && <FrowardIcon />}
            </ItemContainer>
          ))}
        </FlexContainer>

        <Label>ลงทะเบียนบัตร</Label>
        <FlexContainer>
          {menuCardLost.map((menu: Menu, index: number) => (
            <ItemContainer
              onClick={menu.onClick}
              key={index}
              disabled={menu.disabled}
            >
              <Item>
                {menu.icon}
                <TextContainer>
                  <div>{menu.label}</div>
                  <SubLabelDownloadApp>{menu.subLabel}</SubLabelDownloadApp>
                </TextContainer>
              </Item>
              {cardNumber != null && <FrowardIcon />}
            </ItemContainer>
          ))}
        </FlexContainer>


        {/* <Label>ติดตั้ง TSB Go Plus เพื่อสิทธิ์ประโยชน์ที่มากขึ้น</Label>
        <FlexContainer>
          {menuTSBGOList.map((menu: Menu,index:number) =>(

          <ItemContainer
            onClick={menu.onClick}>
            <Item>
                {menu.icon}
            </Item>
          </ItemContainer>
          ))}
        </FlexContainer> */}
      </BottomContainer>
      
      <LoadingOverlay open={loading} />
    </>
  );
};

export default Home;
