import { useEffect, lazy, Suspense } from 'react';
import { Routes, Route, Navigate, Link } from 'react-router-dom';

import Layout from 'components/Layout';
import LayoutBankListQRPayment from 'components/LayoutBankListQRPayment';
import LayoutHowToQRPayment from 'components/LayoutHowToQRPayment';
import LoadingScreenFallback from 'components/LoadingScreenFallback';

const Home = lazy(() => import('./pages/Home'));
const BankListQRPayment = lazy(() => import('./pages/BankListQRPayment'));
const HowToQRPayment = lazy(() => import('./pages/HowToQRPayment'));
const QRPayment = lazy(() => import('./pages/QRPayment'));
const TopupComplete = lazy(() => import('./pages/TopupComplete'));

function App() {
  useEffect(() => {
    const documentHeight = () => {
      const doc = document.documentElement;
      doc.style.setProperty('--doc-height', `${window.innerHeight}px`);
    };
    window.addEventListener('resize', documentHeight);
    documentHeight();
    return () => {
      window.removeEventListener('resize', documentHeight);
    };
  });
  return (
    <Suspense fallback={<LoadingScreenFallback />}>
      <Routes>
        <Route element={<Layout Header="เติมเงินบัตร Thai Smile Bus" />}>
          <Route path="/topup" element={<Home />} />
        </Route>
        <Route element={<LayoutBankListQRPayment />}>
          <Route path="/topup/qr" element={<BankListQRPayment />} />
        </Route>
        <Route element={<LayoutHowToQRPayment />}>
          <Route
            path="/topup/qr/:bank/how-to-qr-payment"
            element={<HowToQRPayment />}
          />
        </Route>
        <Route element={<Layout Header="QR code" />}>
          <Route path="/topup/qr/:bank/qr-payment" element={<QRPayment />} />
        </Route>
        <Route element={<Layout Header="เติมเงินบัตร Thai Smile Bus" />}>
          <Route path="/topup-complete" element={<TopupComplete />} />
        </Route>
	<Route path="*" element={<Navigate to="/topup" replace />} />
      </Routes>
    </Suspense>
  );
}
export default App;