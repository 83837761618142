import { post } from 'services';
import * as requestBodyType from '../requestBodyTypes';
import * as responseBodyType from '../responseBodyType';

export const getCardStatus = (
  body: requestBodyType.getCardStatus,
  controller?: AbortController
) => {
  return post<responseBodyType.ResponseGetCardStatus>(
    '/lenz/getCardStatus',
    body,
    controller
  );
};
