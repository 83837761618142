import React from 'react';
import styled from 'styled-components';
import { SpinnerCircularFixed } from 'spinners-react';

import { ReactComponent as Logo } from 'assets/Logo.svg';

const Container = styled.div`
  height: 64px;
  display: flex;
  justify-content: center;
`;

const HeaderContainer = styled.div`
  display: flex;
  align-items: center;
  color: white;
  font-weight: 600;
  font-size: 18px;
`;

const LogoContainer = styled.div`
  display: flex;
  justify-content: center;
  margin: 1.5rem 0 1rem 0;
`;

const ContentContainer = styled.div`
  background-color: white;
  border-radius: 12px 12px 0px 0px;
  min-height: calc(var(--doc-height) - 64px);
  display: flex;
  flex-direction: column;
  align-items: center;
`;

const LoadingContainer = styled.div`
  margin: auto;
`;

const LoadingScreenFallback = () => {
  return (
    <>
      <Container>
        <HeaderContainer>{'เติมเงินบัตร Thai Smile Bus'}</HeaderContainer>
      </Container>
      <ContentContainer>
        <LogoContainer>
          <Logo />
        </LogoContainer>
        <LoadingContainer>
          <SpinnerCircularFixed
            size={'5rem'}
            thickness={100}
            speed={100}
            color="rgba(37, 141, 158, 1)"
            secondaryColor="rgba(0, 0, 0, 0.44)"
          />
        </LoadingContainer>
      </ContentContainer>
    </>
  );
};

export default LoadingScreenFallback;
