import { post } from 'services';
import * as requestBodyType from '../requestBodyTypes';
import * as responseBodyType from '../responseBodyType';

export const getDeepLinkScb = (
  body: requestBodyType.DeepLink,
  controller?: AbortController
) => {
  return post<responseBodyType.ResDeepLinkScb>(
    '/scb/deeplink',
    body,
    controller
  );
};

export const getDeepLinkKtb = (
  body: requestBodyType.DeepLink,
  controller?: AbortController
) => {
  return post<responseBodyType.ResDeepLinkKtb>(
    '/ktb/deeplink',
    body,
    controller
  );
};

export const getDeepLinkKtbStatus = (
  body: requestBodyType.DeepLinkKtbStatus,
  controller?: AbortController
) => {
  return post<responseBodyType.ResDeeplinkKtbStatus>(
    '/ktb/deeplink/status',
    body,
    controller
  );
};
