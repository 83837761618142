import { bankList } from 'constants/BankList';

export function isObjKey<T>(key: PropertyKey, obj: T): key is keyof T {
  return key in obj;
}

export const isValidBank = (name: string | null | undefined) => {
  if (name == null) {
    return false;
  } else if (isObjKey(name, bankList) && !bankList[name].disabled) {
    return true;
  }
  return false;
};

export const isValidCardNumber = (cardNo: string | null | undefined) => {
  const onlyNumber = /^[0-9]*$/;
  if (cardNo != null && cardNo !== '' && cardNo.length === 16) {
    if (onlyNumber.test(cardNo)) {
      return true;
    }
  }
  return false;
};

export const regexValidAmount = /^\d*(\.\d{0,2})?$/; // match all number or decimal only 0-2 digits
export const isValidAmount = (amount: string | null | undefined) => {
  if (amount !== '' && amount != null) {
    if (regexValidAmount.test(amount!)) {
      const floatAmount = parseFloat(amount ?? '0');
      if (floatAmount !== 0) {
        return true;
      }
    }
  }
  return false;
};
