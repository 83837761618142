import React from 'react';
import styled from 'styled-components';
import { Outlet } from 'react-router-dom';

const Container = styled.div`
  height: 64px;
  display: flex;
  justify-content: center;
`;

const HeaderContainer = styled.div`
  display: flex;
  align-items: center;
  color: white;
  font-weight: 600;
  font-size: 18px;
`;

const ContentContainer = styled.div`
  background-color: white;
  border-radius: 12px 12px 0px 0px;
  min-height: calc(var(--doc-height) - 64px);
`;

type Props = {
  Header: string;
};

const Layout = ({ Header }: Props) => {
  return (
    <>
      <Container>
        <HeaderContainer>{Header}</HeaderContainer>
      </Container>
      <ContentContainer>
        <Outlet />
      </ContentContainer>
    </>
  );
};

export default Layout;
