import React from 'react';
import styled from 'styled-components';
import {
  Outlet,
  useParams,
  useNavigate,
  Navigate,
  useSearchParams,
} from 'react-router-dom';
import { isValidCardNumber, isValidBank, isValidAmount } from 'utils';

import { ReactComponent as BackIcon } from 'assets/BackIcon.svg';

const Container = styled.div`
  height: 64px;
  display: flex;
  justify-content: center;
  width: 100%;
  position: relative;
`;

const Header = styled.div`
  display: flex;
  align-items: center;
  color: white;
  font-weight: 600;
  font-size: 18px;
`;

const ContentContainer = styled.div`
  background-color: white;
  border-radius: 12px 12px 0px 0px;
  position: relative;
  min-height: calc(var(--doc-height) - 64px);
`;

const BackButtonContainer = styled.div`
  width: 32px;
  height: 100%;
  display: flex;
  align-items: center;
  position: absolute;
  left: 20px;
`;

const BackButton = styled(BackIcon)``;

const LayoutHowToQRPayment = () => {
  const { bank } = useParams();
  let [searchParams] = useSearchParams();
  const cardNo = searchParams.get('card_no');
  const amount = searchParams.get('amount');
  const navigate = useNavigate();

  if (
    !isValidBank(bank) ||
    !isValidCardNumber(cardNo) ||
    !isValidAmount(amount)
  ) {
    if (cardNo == null) {
      return <Navigate to="/topup" replace />;
    } else {
      return <Navigate to={`/topup?card_no=${cardNo}`} replace />;
    }
  }

  const handleClickBack = () => {
    if (cardNo == null) {
      navigate({
        pathname: `/topup/qr`,
      });
    } else {
      navigate({
        pathname: `/topup/qr`,
        search: `?card_no=${cardNo}`,
      });
    }
  };

  return (
    <>
      <Container>
        <BackButtonContainer onClick={handleClickBack}>
          <BackButton />
        </BackButtonContainer>
        <Header>วิธีการชำระเงินผ่าน QR Code</Header>
      </Container>
      <ContentContainer>
        <Outlet />
      </ContentContainer>
    </>
  );
};

export default LayoutHowToQRPayment;
