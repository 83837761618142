import React from 'react';
import ReactDOM from 'react-dom/client';
import { BrowserRouter } from 'react-router-dom';
import App from './App';
import reportWebVitals from './reportWebVitals';
import { createGlobalStyle } from 'styled-components';

const GlobalStyle = createGlobalStyle`
:root {
  --clr-bg:linear-gradient(192.39deg, #2B395F 0%, #258D9E 100%);
  --clr-secondary:#DBBA3B;
}

  html {
  height: 100%;
  }
  
  body {
    margin: 0;
    padding: 0;
    font-family: IBM Plex Sans Thai, sans-serif;
    background: var(--clr-bg);
    min-height: 100%;
  }

  button {
    font-family: IBM Plex Sans Thai, sans-serif;
  }
`;

const root = ReactDOM.createRoot(
  document.getElementById('root') as HTMLElement
);
root.render(
  <React.StrictMode>
    <BrowserRouter>
      <GlobalStyle />
      <App />
    </BrowserRouter>
  </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
